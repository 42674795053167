@import '../utils/variables';
@import '../utils/mixins';

.hero-banner {
    display: flex;
    background-color: $pink;
    background-image: url(../../images/hero-background.webp);
    color: $white;
    min-height: 300px;

    @include not-mobile {
        min-height: 200px;
    }
}

.hero-banner-list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include not-mobile {
        flex-direction: row;
    }
}

.hero-banner-item {
    display: flex;
    align-items: center;
    margin: 10px 0;

    @include not-mobile {
        margin: 0;
        width: 33%;
    }
}

.hero-banner-image {
    width: 90px;

    @include not-mobile {
        width: auto;
    }
}

.hero-banner-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    margin-left: 10px;

    @include tablet {
        width: 40%;
    }

    @include desktop {
        font-size: 22px;
        line-height: 25px;
        width: 60%;
    }
}
