@import 'fonts';
@import '../utils/variables';
@import '../utils/mixins';

body {
    font-family: 'Nunito', sans-serif;
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;

    @include tablet {
        width: 750px;
    }

    @include desktop {
        width: 970px;
    }
}

.overlay {
    overflow: hidden;
}

.pink {
    color: $pink;
}
