@import '../utils/variables';

.header {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.header-top {
    display: none;

    @include not-mobile {
        display: flex;
        justify-content: space-between;
    }
}

.header-top-link {
    color: $white;

    @include not-mobile {
        &:hover {
            color: $bright-pink;
        }
    }
}

.logo {
    width: 200px;
    max-width: 200px;

    @include desktop {
        width: 280px;
        max-width: 280px;
    }
}

.header-top-item {
    color: $white;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-decoration: none;
    padding: 5px 15px;
    align-self: center;

    @include tablet {
        padding: 10px 0;
    }

    @include desktop {
        padding: 10px 15px;
    }

    div {
        display: flex;
        align-items: center;
    }
}

.header-top-text {
    display: inline-block;
    margin-left: 4px;
}

.navigation {
    display: none;

    @include not-mobile {
        display: block;
        background-color: $dark-gray;
        box-shadow: 0 2px 6px 1px #0000004a;
        border-radius: 5px;
    }
}

.navigation-list {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.navigation-item {
    flex: 1;
    border-left: .5px solid $gray;
    text-align: center;

    &:first-child {
        border-left: none;
    }
}

.navigation-item-link {
    display: block;
    padding: 10px 15px;
    color: $white;
    font-size: 20px;
    line-height: 35px;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        color: $bright-pink;
    }
}

.header-top-hamburger {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include not-mobile {
        display: none;
    }
}

.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: none;
    border: none;
    cursor: pointer;

    .bar {
        width: 30px;
        height: 3px;
        background-color: $white;
        margin: 4px 0;
        transition: .3s;
        border-radius: 5px;

        &.open {
            background-color: $bright-pink;;
        }
    }
}

.navigation-hamburger {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 217px;
    background-color: $dark-gray;
    box-shadow: 0 2px 6px 1px #0000004a;
    z-index: 100;

    &.open {
        display: block;
    }
}

.navigation-hamburger-list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 0;
}

.navigation-hamburger-item {
    width: 100%;
    text-align: center;
}

.navigation-hamburger-item-link {
    display: block;
    padding: 10px 15px;
    color: $white;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    text-decoration: none;
}
