@import '../utils/variables';
@import '../utils/mixins';

.gallery {
    background-color: $bright-gray;
}

.gallery-title {
    background-color: $pink;
    background-image: url(../../images/hero-background.webp);
    height: 100px;
    display: flex;
    align-items: center;

    @include not-mobile {
        height: 150px;
    }

    h2 {
        font-size: 30px;
        font-weight: 700;

        @include not-mobile {
            font-size: 38px;
        }
    }
}

.gallery-title-wrapper {
    color: $white;
    font-size: 20px;
    display: flex;
    justify-content: space-between;

    @include not-mobile {
        font-size: 26px;
    }
}

.gallery-button {
    color: $white;
    background-color: transparent;
    border: 3px solid $white;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    transition: background-color 0.3s, color 0.3s;

    @include not-mobile {
        padding: 8px 20px;

        &:hover {
            background-color: rgba(255, 255, 255, 0.2);
            color: $white;
        }
    }

    &:focus {
        outline: none;
    }
}

.gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 5px;

    @include not-mobile {
        // grid-template-columns: repeat(3, 1fr);
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
    }
}

.gallery-item {
    width: 100%;
    height: 200px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;

    @include tablet {
        height: 320px;

        &:hover {
            transform: scale(1.04);
        }
    }

    @include desktop {
        height: 500px;

        &:hover {
            transform: scale(1.03);
        }
    }

    @include wide-desktop {
        &:hover {
            transform: scale(1.02);
        }
    }
}

.gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.swiper-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    overflow: hidden;
    outline: 0;
    pointer-events: none;
}

.swiper-popup-body {
    position: relative;
    width: 100%;
    max-width: 90%;
    height: 580px;
    max-height: calc(100% - 50px);
    display: flex;
    justify-content: center;
    margin: 0 40px;
    padding: 0;
    background-color: $white;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, .16);
    border-radius: 5px;
    pointer-events: auto;

    @include tablet {
        max-width: 740px;
        height: 680px;
    }

    @include desktop {
        max-width: 840px;
        height: 680px;
    }

    @include wide-desktop {
        max-width: 1200px;
        height: 740px;
    }
}

.swiper-popup-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5px;
    overflow: hidden;

    @include not-mobile {
        padding: 10px;
    }
}

.swiper-popup-image {
    min-height: 570px;
    max-height: 570px;
    width: 100%;
    object-fit: cover;

    @include tablet {
        min-height: 660px;
        max-height: 660px;
    }

    @include desktop {
        min-height: 660px;
        max-height: 660px;
    }

    @include wide-desktop {
        min-height: 720px;
        max-height: 720px;
    }
}

.swiper-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: auto;
    width: 100%;
    height: 100%;
    background-color: $dark-overlay;
    opacity: .45;
    z-index: 20;
    cursor: pointer;
}

.close-button {
    position: absolute;
    top: -8px;
    right: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    background-color: $pink;
    border: 2px solid $white;
    border-radius: 50%;
    cursor: pointer;
    z-index: 101;
    transition: background-color 0.3s;

    @include not-mobile {
        top: -10px;
        right: -10px;
        width: 36px;
        height: 36px;
        border: 3px solid $white;

        &:hover {
            background-color: $bright-pink;
        }
    }
}

.swiper-button-next,
.swiper-button-prev {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    background-color: $pink;
    border: 2px solid $white;
    border-radius: 50%;
    cursor: pointer;
    z-index: 101;
    transition: background-color 0.3s;

    @include not-mobile {
        width: 36px;
        height: 36px;
        border: 3px solid $white;

        &:hover {
            background-color: $bright-pink;
        }
    }
}

.swiper-button-next {
    &::after {
        content: '';
        display: inline-block;
        border: solid $white;
        border-width: 0 3px 3px 0;
        padding: 3px;
        margin-right: 2px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);

        @include not-mobile {
            border-width: 0 4px 4px 0;
            padding: 4px;
            margin-right: 4px;
        }
    }
}

.swiper-button-prev {
    &::after {
        content: '';
        display: inline-block;
        border: solid $white;
        border-width: 0 3px 3px 0;
        padding: 3px;
        margin-left: 2px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);

        @include not-mobile {
            border-width: 0 4px 4px 0;
            padding: 4px;
            margin-right: 1px;
        }
    }
}
