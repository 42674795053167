@import '../utils/variables';
@import '../utils/mixins';

.footer {
    width: 100%;
    height: 240px;
    background: $dark-gray;
    background-repeat: repeat;
    display: flex;
    align-items: center;

    @include not-mobile {
        height: 120px;
    }
}

.footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;

    @include not-mobile {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: auto;
    }
}

.footer-company {
    display: flex;
    flex-direction: column;
    color: $white;
    font-weight: 18px;
}

.footer-socials-item {
    display: inline-block;
    margin-right: 10px;
}

.footer-logo {
    width: 200px;
    max-width: 200px;
}
