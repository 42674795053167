@import '../utils/variables';
@import '../utils/mixins';

.services {
    background-color: $bright-gray;
}

.services-wrapper {
    padding: 20px 15px 10px;

    @include not-mobile {
        padding: 30px 15px;
    }
}

.services-title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;

    @include not-mobile {
        font-size: 38px;
    }
}

.services-sub-title {
    font-size: 22px;
    font-weight: 700;

    @include not-mobile {
        font-size: 28px;
    }
}

.services-text {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.services-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.services-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;

    @include not-mobile {
        flex-direction: row;
    }
}

.services-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    margin-top: 20px;

    @include not-mobile {
        margin-top: 0;
        margin-left: 30px;
    }
}

.services-item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.services-checkmark {
    display: inline-block;
    margin-right: 8px;
    margin-top: 3px;
}

.services-text {
    font-size: 18px;
    font-weight: 300;
}

.services-title-small {
    font-weight: 700;
    font-size: 18px;

    @include not-mobile {
        font-size: 22px;
    }
}

.services-text-small {
    margin-left: 20px;
}
