@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 768px) and (max-width: 1024px) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: 1025px) {
        @content;
    }
}

@mixin wide-desktop {
    @media (min-width: 1441px) {
        @content;
    }
}

@mixin not-mobile {
    @media (min-width: 768px) {
        @content;
    }
}
