@import '../utils/variables';
@import '../utils/mixins';

.contacts {
    background: $dark-gray;
    background-repeat: repeat;
    border-top: 1px solid #52444e;
}

.info {
    padding: 20px 15px;

    @include not-mobile {
        padding: 25px 15px;
    }
}

.info-title {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 20px;
    font-size: 20px;

    @include not-mobile {
        font-size: 26px;
    }
}

.info-text {
    display: flex;
    flex-direction: column;
    color: $white;

    @include not-mobile {
        flex-direction: row;
    }
}

.info-text-item {
    &:first-child {
        margin: 0 0 20px 0;

        @include not-mobile {
            margin: 0 60px 0 0;
        }
    }
}

.info-text-title {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 18px;

    @include not-mobile {
        font-size: 24px;
    }
}

.info-text-paragraph {
    display: flex;
    align-items: center;
}

.info-text-paragraph-content {
    font-size: 18px;
    font-weight: 300;
    display: inline-block;
    margin-left: 4px;
}
