@import '../utils/variables';
@import '../utils/mixins';

.about {
    display: flex;
    background-color: $pink;
    background-image: url(../../images/hero-background.webp);
    min-height: 350px;
    color: $white;
    border-bottom: 1px solid $white;

    @include not-mobile {
        min-height: 250px;
    }
}

.about-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 15px 15px;

    @include not-mobile {
        flex-direction: row;
        padding: 30px 15px 20px;
    }
}

.about-title {
    font-size: 30px;
    font-weight: 700;

    @include not-mobile {
        font-size: 38px;
    }
}

.about-text {
    font-size: 20px;
    font-weight: 300;
}

.about-video-wrapper {
    margin-bottom: 10px;
    margin-right: 0;

    @include not-mobile {
        margin-bottom: 0;
        margin-right: 30px;
    }
}

.about-video {
    width: 270px;
    max-width: 270px;

    @include desktop {
        width: 400px;
        max-width: 400px;
    }
}
