@font-face {
    font-family: 'Nunito';
    src: url('../../fonts/NunitoSans_7pt_Condensed-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../fonts/NunitoSans_7pt_Condensed-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Nunito';
    src: url('../../fonts/NunitoSans_7pt_Condensed-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}
