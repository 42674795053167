@import '../utils/variables';
@import '../utils/mixins';

.slide {
    position: relative;

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #000;
        opacity: .4;
    }
}

.slide-image {
    min-height: 300px;
    max-height: 300px;
    width: 100%;
    object-fit: cover;

    @include tablet {
        min-height: 500px;
        max-height: 500px;
    }

    @include desktop {
        min-height: 700px;
        max-height: 700px;
    }
}

.slide-text {
    color: $white;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    text-transform: uppercase;
    text-align: center;
    padding: 20px 0;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 65%;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .6);

    @include tablet {
        font-size: 30px;
        line-height: 40px;
    }

    @include desktop {
        font-size: 50px;
        line-height: 60px;
    }
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: transparent;
    border: 2px solid $white;
    opacity: 1;

    &-active {
        background-color: $white;
        border: none;
    }
}
